import React from "react";

import birneAnimated from "assets/birne_animated.svg";

interface LoaderProps {
  message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message = "Daten werden geladen" }: LoaderProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <img src={birneAnimated} style={{ height: "250px" }} />
      &nbsp;{message}&nbsp;...
    </div>
  );
};

export default Loader;
