import { useGetMyCompany } from "domain/meinekontakte/UserQueries";
import React, { ReactNode, useContext } from "react";
import Loader from "components/Loader";
import { AuthContext } from "services/authentication";
import { HomeNoFirma } from "domain/HomeNoFirma";
import { useGetKunden } from "./Header/UserQueries";

interface Props {
  children: ReactNode;
}

const UserHasFirma: React.FC<Props> = (props: Props) => {
  // hooks
  const meineFirma = useGetMyCompany();
  const kundenQuery = useGetKunden();
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();
  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  if (!isAuthenticated || (meineFirma.data && meineFirma.data.accountid !== "" && kundenQuery.data && kundenQuery.data.length > 0)) {
    return <>{props.children}</>;
  }
  if (meineFirma.isLoading || meineFirma.isFetching || kundenQuery.isLoading || kundenQuery.isFetching) {
    return <Loader message="Seite wird geladen" />;
  }
  return <HomeNoFirma />;
};
export default UserHasFirma;
