import React, { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from "react";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { log } from "../common/Logger";
import { AuthContext } from "./authentication";
import { Kunde } from "components/Header/UserTypes";

interface Props {
  children: ReactNode;
}

interface ApiContextProps {
  api: AxiosInstance;
  setKunde: (kunde?: Kunde) => void;
  getKunde: () => Kunde | null;
}

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
};

export const ApiContext = createContext<ApiContextProps>({
  api: axios.create(),
  getKunde: () => null,
  setKunde: (_kunde?: Kunde) => 0,
});

export function ApiContextProvider({ children }: Props): ReactElement {
  const authService = useContext(AuthContext);
  const [, setKundeState] = useState<Kunde | null>(null);

  const setKunde = (kunde?: Kunde) => {
    if (kunde) {
      log.debug("saving kunde to LocalStorage %s", kunde.accountid);
      localStorage.setItem("Kunde", JSON.stringify(kunde));
      setKundeState(kunde);
    }
  };
  const getKunde = () => {
    const result = localStorage.getItem("Kunde");
    if (result != null) {
      return JSON.parse(result) as Kunde;
    }
    log.warn("tried to get Kunde from LocalStorage, but didn't find any Kunde; will return 'null' instead");
    return null;
  };

  const api = useMemo(() => {
    const axiosInstance = axios.create(axiosRequestConfiguration);
    log.debug("initializing new axios api-instance");

    axiosInstance.interceptors.request.use(
      async (config) => {
        try {
          log.debug("getting access token from auth-provider via api-context for request %s", config.url);
          const token = await authService.getUser();
          config.headers.Authorization = `Bearer ${token?.access_token}`;
          config.withCredentials = false;
          log.debug("added access token to request-header via api-context for request %s", config.url);
          const kunde = localStorage.getItem("Kunde");
          if (kunde) {
            const kundenid = JSON.parse(kunde).accountid;
            log.debug("adding header field for kundenid: %s", kundenid, config.url);
            config.headers["X-KundeId"] = kundenid;
          }
          return Promise.resolve(config);
        } catch (error) {
          log.error({ obj: error }, "oops during axios request interceptor for bearer tokens");
          return Promise.reject(error);
        }
      },
      (error) => {
        log.error("error while retrieving access token via api-context");
        Promise.reject(error);
      }
    );

    // Trace-Parent für Logging mitgeben
    // TODO: sollte irgendwie pro "Aktion" generiert werden
    // axiosInstance.interceptors.request.use((value) => {
    //   value.headers.TraceParent = "12345-12345-12345";
    //   return value;
    // });

    return axiosInstance;
  }, [authService]);

  return <ApiContext.Provider value={{ api, setKunde, getKunde }}>{children}</ApiContext.Provider>;
}
