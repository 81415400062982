import React, { useContext } from "react";
import { Jumbotron, Row, Col, Container } from "react-bootstrap";
import { AuthContext } from "../services/authentication";

import spash_background from "../assets/splash_background.jpg";
import { ActionButton } from "@gelsenwasser/react";

const Splash: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  const backgroundStyles: React.CSSProperties = {
    backgroundImage: `url(${spash_background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  return (
    <div style={backgroundStyles} className="h-100">
      <Container>
        <Row style={{ marginTop: "10rem" }}>
          <Col md={8} lg={6}>
            <Jumbotron>
              <h1 className="display-4">
                Herzlich Willkommen im <strong>wMSB</strong>-Portal
              </h1>
              <p>
                Hier verwalten Sie Messstellen an all Ihren Standorten. Erstellen Sie in wenigen Schritten sämtliche
                Beauftragungen an die Gelsenwasser AG und behalten diese mühelos im Blick.
              </p>
              <ActionButton onClick={authContext.signinRedirect} caption="Anmelden" />
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Splash;
