import React from "react";
import { Link } from "react-router-dom";

import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import { Card, Button, Badge, Spinner } from "react-bootstrap";
import { BsExclamationTriangleFill } from "react-icons/bs";

import {
  useGetAnzahlAuftraege,
  useGetAnzahlMessstellen,
  useGetAnzahlStandorte,
  // useGetAnzahlTickets,
} from "./DashboardQueries";
import { AuftragsAnzahlData } from "./DashboardTypes";
import { AuftragStatusBadgeVariant } from "domain/auftraege/AuftragTypes";

const Dashboard: React.FC = () => {
  // hooks
  // states
  // parameter
  // queries und mutationen
  const anzahlAuftraegeQuery = useGetAnzahlAuftraege();
  const anzahlMessstellenQuery = useGetAnzahlMessstellen();
  const anzahlStandorteQuery = useGetAnzahlStandorte();
  // const anzahlTicketsQuery = useGetAnzahlTickets();

  // effekte
  // daten
  // handler

  const showAnzahlAuftraege = (query: UseQueryResult<AuftragsAnzahlData[], AxiosError>) => {
    if (query.isLoading) return <Spinner animation="border" role="status" as="span"/>;
    if (query.isError || query.data === undefined)
      return (
        <>
          <BsExclamationTriangleFill className="text-warning" /> Anzahl Aufträge konnte nicht ermittelt werden
        </>
      );
    let totalAuftraege = 0;
    query.data.forEach((element) => {
      totalAuftraege += element.anzahl;
    });
    return (
      <>
        Es gibt aktuell{" "}
        <Badge variant="success" className="rounded-pill">
          {totalAuftraege}
        </Badge>{" "}
        Auftr{totalAuftraege !== 1 ? "äge" : "ag"}.<br />
        <span>
          {Object.keys(AuftragStatusBadgeVariant).map((item) => {
            const auftragsStatus = query.data.find((x) => x.status === item);
            if (!auftragsStatus || auftragsStatus.anzahl === 0) return null;
            return (
              <Badge key={item} variant={AuftragStatusBadgeVariant[item]} className="rounded-pill">
                {auftragsStatus.anzahl + " - " + item}
              </Badge>
            );
          })}
        </span>
      </>
    );
  };

  const showAnzahlMessstellen = (query: UseQueryResult<number, AxiosError>) => {
    if (query.isLoading) return <Spinner animation="border" role="status" as="span" />;
    if (query.isError || query.data === undefined)
      return (
        <>
          <BsExclamationTriangleFill className="text-warning" /> Anzahl Messstellen konnte nicht ermittelt werden
        </>
      );
    return (
      <>
        Es gibt aktuell{" "}
        <Badge variant="success" className="rounded-pill">
          {query.data}
        </Badge>{" "}
        Messstelle{query.data !== 1 ? "n" : null}.
      </>
    );
  };

  const showAnzahlStandorte = (query: UseQueryResult<number, AxiosError>) => {
    if (query.isLoading) return <Spinner animation="border" role="status" as="span" />;
    if (query.isError || query.data === undefined)
      return (
        <>
          <BsExclamationTriangleFill className="text-warning" /> Anzahl Standorte konnte nicht ermittelt werden
        </>
      );
    return (
      <>
        Es gibt aktuell{" "}
        <Badge variant="success" className="rounded-pill">
          {query.data}
        </Badge>{" "}
        Standort{query.data !== 1 ? "e" : null}.
      </>
    );
  };

  // const showAnzahlTickets = (query: UseQueryResult<number, AxiosError>) => {
  //   if (query.isLoading) return <Spinner animation="border" role="status" as="span" />;
  //   if (query.isError || query.data === undefined)
  //     return (
  //       <>
  //         <BsExclamationTriangleFill className="text-warning" /> Anzahl Tickets konnte nicht ermittelt werden
  //       </>
  //     );
  //   return (
  //     <>
  //       Es gibt aktuell{" "}
  //       <Badge variant="success" className="rounded-pill">
  //         {query.data}
  //       </Badge>{" "}
  //       Ticket{query.data !== 1 ? "s" : null}.
  //     </>
  //   );
  // };

  return (
    <>
      <Card>
        <Card.Header as="h5" className="bg-primary text-white">
          Ihre Messstellen
        </Card.Header>
        <Card.Body>
          <Card.Text>{showAnzahlMessstellen(anzahlMessstellenQuery)}</Card.Text>
          <Button size="sm" as={Link} to="/messstellen" className="m-1">
            Anzeigen
          </Button>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header as="h5" className="bg-primary text-white">
          Ihre Standorte
        </Card.Header>
        <Card.Body>
          <Card.Text>{showAnzahlStandorte(anzahlStandorteQuery)}</Card.Text>
          <Button size="sm" as={Link} to="/standorte" className="m-1">
            Anzeigen
          </Button>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header as="h5" className="bg-green text-white">
          Ihre Aufträge
        </Card.Header>
        <Card.Body>
          <Card.Text>{showAnzahlAuftraege(anzahlAuftraegeQuery)}</Card.Text>
          <Button size="sm" as={Link} to="/auftraege" className="m-1">
            Anzeigen
          </Button>
        </Card.Body>
      </Card>
      {/* <Card className="mt-3">
        <Card.Header as="h5" className="bg-green text-white">
          Ihre Tickets
        </Card.Header>
        <Card.Body>
          <Card.Text>{showAnzahlTickets(anzahlTicketsQuery)}</Card.Text>
          <Button size="sm" as={Link} to="/tickets" className="m-1">
            Anzeigen
          </Button>
        </Card.Body>
      </Card> */}
    </>
  );
};

export default Dashboard;
