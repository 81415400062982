import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Nav } from "react-bootstrap";
import { MdDone, MdPowerSettingsNew, MdPeopleOutline } from "react-icons/md";
import { BsPersonFill } from "react-icons/bs";
import { BsBriefcase } from "react-icons/bs";
import { User } from "oidc-client";
import { AuthContext } from "../../services/authentication";
import { Link } from "react-router-dom";
import { useGetKunden } from "./UserQueries";
import { Kunde } from "./UserTypes";
import { log } from "common/Logger";
import { ApiContext } from "services/ApiContext";
import { useQueryClient } from "react-query";
import { useGetMyCompany } from "domain/meinekontakte/UserQueries";
import { useLocation } from "react-router-dom";
import * as qs from "qs";

export default function UserSettings(): ReactElement {
  // hooks
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();
  const { setKunde, getKunde } = useContext(ApiContext);
  const location = useLocation();
  const queryClient = useQueryClient();

  // states
  const [userData, setUserData] = useState<User>();

  // parameter
  // queries und mutationen
  const kundenQuery = useGetKunden();
  const myCompany = useGetMyCompany();

  // effekte
  useEffect(() => {
    // falls die Seite neu geladen wird und die Daten noch nicht geladen wurden ...
    let isCanceled = false;

    const getUserData = async () => {
      const user = await authContext.getUser();
      if (user != null && !isCanceled) setUserData(user);
    };

    getUserData();

    // beim unmount das Setzen des State vermeiden
    return () => {
      isCanceled = true;
    };
  }, [authContext]);

  let currentKundenId = getKunde()?.accountid;
  useEffect(() => {
    if (kundenQuery.data && kundenQuery.data.length > 0 && !currentKundenId) {
      const currentKunde = kundenQuery.data[0];
      currentKundenId = currentKunde.accountid;
      setKunde(currentKunde);
    }
  }, [currentKundenId, kundenQuery.data]);

  const parsedQueryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (kundenQuery.data && kundenQuery.data.length > 0 && parsedQueryString["customerId"]) {
      log.debug("searching for kunde with id %s via querystring", parsedQueryString["customerId"]);
      const kunde = kundenQuery.data.find((x) => x.accountid === parsedQueryString["customerId"]);
      if (kunde) {
        log.debug("found kunde via querystring, setting kunde as active kunde");
        setKunde(kunde);
      } else log.warn("did not find kunde with id %s via query-string", parsedQueryString["customerId"]);
    }
  }, [parsedQueryString, kundenQuery.data]);

  // daten
  // handler
  const onSelectKunde = (kunde: Kunde) => {
    log.debug("changing kundenid %s", kunde.accountid);
    setKunde(kunde);
    queryClient.invalidateQueries();
  };

  return (
    <Nav.Item className="ml-3">
      {isAuthenticated ? (
        <DropdownButton
          title={
            (
              <>
                <span className="d-none d-md-inline">
                  {userData?.profile.given_name + " " + userData?.profile.family_name}
                </span>
                <span className="d-md-none">
                  <BsPersonFill />
                </span>
              </>
            ) ?? "n/a"
          }
          variant="outline-white"
          className="loginButton"
          alignRight
          style={{ zIndex: 1001 }}
        >
          {myCompany.data?.isKunde && (
            <Dropdown.Item as={Link} to="/Fachplaner">
              <BsBriefcase /> Fachplaner
            </Dropdown.Item>
          )}
          {kundenQuery.data && kundenQuery.data.length > 0 && (
            <>
              <Dropdown.Item as={Link} to="/Benutzer">
                <MdPeopleOutline /> Benutzerverwaltung
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          {kundenQuery.data && kundenQuery.data.length > 1 && (
            <>
              <Dropdown.Header>Firmenauswahl</Dropdown.Header>
              {kundenQuery.data.map((kunde) => (
                <Dropdown.Item key={kunde.accountid} onClick={() => onSelectKunde(kunde)}>
                  {currentKundenId === kunde.accountid && <MdDone />}
                  {currentKundenId !== kunde.accountid && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                  {kunde.name}
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item onClick={authContext.logout}>
            <MdPowerSettingsNew /> Abmeldung
          </Dropdown.Item>
        </DropdownButton>
      ) : (
        <Button variant="outline-white" style={{ border: 0 }} onClick={authContext.signinRedirect}>
          Anmelden
        </Button>
      )}
    </Nav.Item>
  );
}
