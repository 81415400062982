import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "./App.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { NotificationProvider } from "@gelsenwasser/react";
import { IDENTITY_CONFIG } from "./services/authentication";
import { AuthProvider, LogInCallback, LogOutCallback } from "./services/authentication";

// static components
import { Layout } from "./components/Layout";
import { Home } from "./domain/Home";
import NoMatch from "./domain/NoMatch";
import { ApiContextProvider } from "./services/ApiContext";
import ProtectedRoute from "./components/ProtectedRoute";
import Loader from "components/Loader";
import UserHasFirma from "components/UserHasFirma";

// dynamic / lazy components./domain/kunde/messstellen/Index
const Messstelle = React.lazy(() => import("./domain/messstellen/Index"))
const Benutzer = React.lazy(() => import("./domain/meinekontakte/Index"));
const Fachplaner = React.lazy(() => import("./domain/fachplaner/Index"));
// const Tickets = React.lazy(() =>  import("./domain/tickets/Index"));
const Auftraege = React.lazy(() => import("./domain/auftraege/Index"))
const Standorte = React.lazy(() => import("./domain/standorte/Index"))

const Anmeldung = React.lazy(() =>  import("./domain/prozesse/anmeldung/Index"));
const Unterbrechung = React.lazy(() =>  import("./domain/prozesse/unterbrechung/Index"));
const Abmeldung = React.lazy(() =>  import("./domain/prozesse/abmeldung/Index"));
const Wechsel = React.lazy(() =>  import("./domain/prozesse/wechsel/Index"));

const queryClient = new QueryClient();
export default class App extends React.Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <>
        <AuthProvider config={IDENTITY_CONFIG}>
          <ApiContextProvider>
            <NotificationProvider>
              <QueryClientProvider client={queryClient}>
                <Layout>
                  <Suspense fallback={<Loader message="Seite wird geladen" />}>
                    <UserHasFirma>
                    <Switch>
                      {/* Home */}
                      <Route exact path="/" component={Home} />

                      {/* Authentication */}
                      <Route path="/signin-oidc" component={LogInCallback} />
                      <Route path="/signout-oidc" component={LogOutCallback} />

                      {/* Kundenbereich */}
                      <ProtectedRoute path="/benutzer" component={Benutzer} />
                      <ProtectedRoute path="/fachplaner" component={Fachplaner} />

                      <ProtectedRoute path="/messstellen" component={Messstelle} />
                      {/* <ProtectedRoute path="/tickets" component={Tickets} /> */}
                      <ProtectedRoute path="/auftraege" component={Auftraege} />
                      <ProtectedRoute path="/standorte" component={Standorte} />

                      <ProtectedRoute path="/anmeldung" component={Anmeldung} />
                      <ProtectedRoute path="/stromunterbrechung" component={Unterbrechung} />
                      <ProtectedRoute path="/abmeldung" component={Abmeldung} />
                      <ProtectedRoute path="/geraetewechsel" component={Wechsel} />

                      {/* Unbekannte Seite */}
                      <Route path="*" component={NoMatch} />

                    </Switch>
                    </UserHasFirma>
                  </Suspense>
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </NotificationProvider>
          </ApiContextProvider>
        </AuthProvider>
      </>
    );
  }
}
