import { DateOrEmpty } from "@gelsenwasser/react";

export interface Auftrag {
  id: string;
  auftragsnummer: string;
  name: string;
  typ: string;
  standort: string;
  standortid: string;
  messstellen: number;
  gestelltAm: Date;
  status: string;
}

export interface AuftragDetails extends Auftrag {
  sendungsverfolgungsUrl: string;
  sendungsverfolgungsNummer: string;
  versandstatus: string;
  lieferDetails: LieferDetails;
  typId: number;
}

export interface StandortAuftrag {
  id: string;
  name: string;
  typ: string;
  gestelltAm: Date;
  auftraggeber: string;
  antragsteller: string;
  status: string;
}

export interface LieferDetails {
  firma: string;
  ansprechpartner: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  telefon: string;
  email: string;
}

interface GeraeteDaten {
  zaehlerSerialNr: string;
  wandler1: string;
  wandler2: string;
  wandler3: string;
  modemSerialNr: string;
  simKartenNr: string;
}
export interface MessstellenAenderung {
  /**
   * GUID des MessstellenAenderung
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  messstellenAenderungId: string;
  /**
   * @deprecated besser messstellenAenderungId verwenden
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  id: string;
  /**
   * Name des Messstellenänderung
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  name: string;
  /**
   * Name der Messstelle
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  messstelleName: string;
  /**
   * ID des Types der Messstellenänderung
   *
   * @type {AenderungsTypId}
   * @memberof MessstellenAenderung
   */
  aenderungsTypId: AenderungsTypId;
  /**
   * Name des Types der Messstellenänderung
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  aenderungsTypName: string;
  /**
   * Bezeichnung der Messaufgabe
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  messaufgabe: string;
  /**
   * Text mit Warnungen (aus Dynamics generiert)
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  warnung: string;
  /**
   * (Wunsch)Termin der Messstellenänderung
   *
   * @type {DateOrEmpty}
   * @memberof MessstellenAenderung
   */
  wunschTermin: DateOrEmpty;
  /**
   * Geplanter Termin der Messstellenänderung
   *
   * @type {DateOrEmpty}
   * @memberof MessstellenAenderung
   */
  geplanterTermin: DateOrEmpty;
  /**
   * Tatsächlicher Termin der Messstellenänderung
   *
   * @type {DateOrEmpty}
   * @memberof MessstellenAenderung
   */
  tatsaechlicherTermin: DateOrEmpty;
  /**
   * GUID der Messstelle
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  messstelleId: string;
  zaehlernummer: string;
  /**
   * GUID des Auftrag
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  aufragId: string;
  einbau: GeraeteDaten;
  ausbau: GeraeteDaten;
  unterbrechungVon: DateOrEmpty;
  unterbrechungBis: DateOrEmpty;
  /**
   * ID des Mako-Status
   *
   * @type {MakoStatus}
   * @memberof MessstellenAenderung
   */
  makoStatusId: MakoStatus;
  /**
   * Name des Mako-Status
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  makoStatus: string;
  /**
   * ID des I-Antrag-Status
   *
   * @type {IAntragStatus}
   * @memberof MessstellenAenderung
   */
  iAntragStatusId: IAntragStatus;
  /**
   * Name des I-Antrag-Status
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  iAntragStatus: string;
  /**
   * Name des Bearbeitungsstatus
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  bearbeitungsStatus: string;
  /**
   * ID des Geräteeinbaustatus
   *
   * @type {GeraeteEinbauStatus}
   * @memberof MessstellenAenderung
   */
  geraeteEinbauStatusId: GeraeteEinbauStatus;
  /**
   * ID des Geräteausbaustatus
   *
   * @type {GeraeteAusbauStatus}
   * @memberof MessstellenAenderung
   */
  geraeteAusbauStatusId: GeraeteAusbauStatus;
  /**
   * Name des Geräteeinbaustatus
   *
   * @type {string}
   * @memberof MessstellenAenderung
   */
  geraeteEinbauStatus: string;
  /**
   * Aktiv
   *
   * @type {boolean}
   * @memberof MessstellenAenderung
   * @description Boolean, umgewandelt aus dem Status-Code (0=aktiv, 1=inaktiv), wobei !=0 gleich inaktiv bedeutet
   */
  aktiv: boolean;
}

export interface AuftragViewParameterTypes {
  auftragid: string;
}

export const AuftragStatusBadgeVariant = {
  Neu: "primary",
  Offen: "warning",
  Abgeschlossen: "success",
} as const;

export const VersandtStatusBadgeVariant = {
  "in Bearbeitung": "warning",
  "Versand wird vorbereitet": "warning",
  "Versand wurde vorgemerkt": "warning",
  Versendet: "success",
} as const;

export enum MakoStatus {
  Offen = 971080000,
  Angemeldet = 971080001,
  Bestaetigt = 971080002,
}

export enum IAntragStatus {
  Offen = 971080000,
  Gestellt = 971080001,
  Bestaetigt = 971080002,
}

export enum AenderungsTypId {
  Inbetriebnahme = 971080000,
  Uebernahme = 971080001,
  Geraetewechsel = 971080002,
  Stromunterbrechung = 971080003,
  Stilllegung = 971080004,
  Auszug = 971080005,
}

export enum GeraeteEinbauStatus {
  Offen = 971080000,
  Abgeschlossen = 971080001,
}

export enum GeraeteAusbauStatus {
  Offen = 971080000,
  Abgeschlossen = 971080001,
}
