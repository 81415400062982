import React, { useContext, useEffect, useState } from "react";
import { Alert, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserSettings from "./UserSettings";

import gw_logo from "assets/gw_logo.png";
import { ApiContext } from "services/ApiContext";
import { AuthContext } from "services/authentication";

const Header: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();
  const { getKunde } = useContext(ApiContext);

  // states
  const [kundenname, setKundenname] = useState("");

  // effekte
  const customerName = getKunde()?.name;
  useEffect(() => {
    if (kundenname !== customerName) {
      setKundenname(customerName ?? "");
    }
  }, [customerName]);

  // daten
  // handler

  return (
    <header>
      <Navbar expand={false} bg="gradient" variant="dark">
        <Navbar.Brand as={Link} to="/" className="ml-2 pr-2">
          <strong>wMSB</strong>
          <span className="font-weight-light">
            Portal
            {isAuthenticated && kundenname && " | " + kundenname}
          </span>
        </Navbar.Brand>

        {process.env.REACT_APP_ENV?.toLowerCase() !== "prod" && (
          <Alert variant="warning">
            <div className="font-weight-bold">Umgebung: {process.env.REACT_APP_ENV?.toUpperCase()}</div>
          </Alert>
        )}

        <div className="d-flex">
          <img src={gw_logo} className="d-none d-sm-block align-self-center" />
          <UserSettings />
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
