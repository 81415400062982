import React, { PropsWithChildren } from "react";
import { Breadcrumb } from "react-bootstrap";
import { BsHouseDoor } from "react-icons/bs";
import { Link, matchPath, useLocation } from "react-router-dom";

import { log } from "common/Logger";
import { RouteConfig } from "../common/RouteConfig";

interface Props {
  /**
   * Array of routes to inspect for route-based breadcrumb
   *
   * @type {RouteConfig[]}
   * @memberof Props
   */
  routes?: Array<RouteConfig>;
  /**
   * base url (if routes are relative to this)
   *
   * @type {string}
   * @memberof Props
   */
  matchUrl: string;
}

interface InnerProps extends Props {
  /**
   * should a custom breadcrumb be rendered (must be provided via children!)
   *
   * @type {boolean}
   * @memberof InnerProps
   */
  showCustom: boolean;
}

const removeTrailingSlash = (url: string): string => {
  return url.endsWith("/") ? url.substr(0, url.length - 1) : url;
};

export const hasRouteCustomBreadcrumb = (routes: Array<RouteConfig>, matchUrl: string, location: string): boolean => {
  const cleanedLocation = removeTrailingSlash(location);
  const firstMatchingRoute = routes.find(
    (item) => !!matchPath(cleanedLocation, { path: matchUrl + item.path, exact: item.exact })
  );
  return !!firstMatchingRoute?.hasCustomBreadrumb ?? false;
};

/**
 * rendering of the breadcrumb based on the routes given
 *
 * @param {*} props
 * @returns
 */
const RouteBreadcrumb: React.VFC<Props> = (props) => {
  return <InnerBreakcrumb {...props} showCustom={false} />;
};

export default RouteBreadcrumb;

/**
 * the actual breadcrumb component. If showCustom is true, the children will be rendered as the title for the active breadcrumb-item, otherwise the title from routes will be used
 *
 * @param {PropsWithChildren<InnerProps>} {
 *   routes = [],
 *   matchUrl,
 *   showCustom,
 *   children,
 * }
 * @returns
 */
const InnerBreakcrumb: React.FC<InnerProps> = ({
  routes = [],
  matchUrl,
  showCustom,
  children,
}: PropsWithChildren<InnerProps>) => {
  // hooks
  const location = useLocation();

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  matchUrl = removeTrailingSlash(matchUrl);
  const cleanedLocation = removeTrailingSlash(location.pathname);
  log.debug("matchUrl: %s ; cleaned path: %s", matchUrl, cleanedLocation);
  log.trace({ obj: routes }, "routes to examine");

  let hasCustomBreadcrumb = false;
  let isActive = false;

  return (
    <Breadcrumb className="mt-3 d-print-none">
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/", className:"d-flex align-items-center" }}>
        <BsHouseDoor className="mr-2" />Home
      </Breadcrumb.Item>
      {routes
        // nur routen anzeigen, die im aktuellen Pfad enthalten sind
        .filter((item) => !!matchPath(cleanedLocation, { path: matchUrl + item.path }))
        // routen als breadcrumb anzeigen
        .map((item, index) => {
          // wenn wir eine aktive Route gefunden haben, dann sind alle anderen Routen egal (falls es noch welche gibt)
          if (!isActive) {
            isActive = !!matchPath(cleanedLocation, { path: matchUrl + item.path, exact: item.exact });
            log.debug(
              "comparing %s with cleaned path; is active: %s; has custom breadcrumb %s (will show %s)",
              matchUrl + item.path,
              isActive,
              item.hasCustomBreadrumb,
              showCustom
            );

            if (!showCustom && !hasCustomBreadcrumb && !!item.hasCustomBreadrumb) hasCustomBreadcrumb = true;
            return (
              <Breadcrumb.Item key={index} active={isActive} linkAs={Link} linkProps={{ to: matchUrl + item.path }}>
                {item.hasCustomBreadrumb ? children : item.title}
              </Breadcrumb.Item>
            );
          }
        })}
    </Breadcrumb>
  );
};

export /**
 * rendering of the breadcrumb, the children are used as the title of the active breadcrumb-item
 *
 * @param {*} props
 * @returns
 */
const CustomRouteBreadcrumb: React.FC<Props> = (props) => {
  return <InnerBreakcrumb {...props} showCustom={true} />;
};
