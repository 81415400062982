import { useGetKunden } from "components/Header/UserQueries";
import React, { useContext, useEffect } from "react";

import { Col, Row, Jumbotron, Container, Alert } from "react-bootstrap";
import { ApiContext } from "services/ApiContext";
import RouteBreadcrumb from "../components/RouteBreadcrumb";
import { AuthContext } from "../services/authentication";
import Dashboard from "./dashboard/Dashboard";
import Prozesseinstieg from "./dashboard/Prozesseinstieg";
import Splash from "./Splash";

export const Home: React.FC = () => {
  // hooks
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();
  const { getKunde } = useContext(ApiContext);

  // states
  // parameter
  // queries und mutationen
  const kundenQuery = useGetKunden();

  // effekte
  useEffect(() => {
    document.title = "wMSB Portal";
  }, []);

  // daten
  const customerName = getKunde()?.name;

  // handler

  if (!isAuthenticated) return <Splash />;
  else
    return (
      <>
        <Container>
          <RouteBreadcrumb matchUrl="/" />
        </Container>

        <Container>
          <Row className="mt-0">
            <Col md={8}>
              <Jumbotron>
                <h1 className="display-4">
                  Willkommen im <strong>wMSB</strong>-Portal
                </h1>
                <p>
                  Hier verwalten Sie Messstellen an all Ihren Standorten. Erstellen Sie in wenigen Schritten sämtliche
                  Beauftragungen an die Gelsenwasser AG und behalten diese mühelos im Blick.
                </p>
              </Jumbotron>

              {kundenQuery.data && kundenQuery.data.length > 1 && (
                <Alert variant="info" className="m-4">
                  Sie sind angemeldet für die Firma <strong>{customerName}</strong>. Nicht die richtige Firma? Dann
                  wählen Sie bitte über das Benutzermenü oben rechts eine andere Firma.
                </Alert>
              )}

              <Prozesseinstieg />
            </Col>

            <Col md={4}>
              <Dashboard />
            </Col>
          </Row>
        </Container>
      </>
    );
};
