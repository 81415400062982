import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

import NotFound from "../assets/404.png";

interface NoMatchProps {
  title?: string;
  text?: string;
  actionCaption?: string;
  actionLinkTo?: string;
}

const NoMatch: React.VFC<NoMatchProps> = ({
  title = "wMSB Portal",
  text = "Sie können einfach unsere Startseite neu aufrufen.",
  actionCaption = "Startseite",
  actionLinkTo = "/",
}: NoMatchProps) => {
  // effekte
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="bg-dark h-100 overflow-hidden">
      <Container className="mt-5 text-white d-flex align-items-center justify-content-center">
        <Row className="mt-0">
          <Col sm={6}>
            <img src={NotFound} style={{ maxHeight: "90%", maxWidth: "90%" }} />
          </Col>
          <Col sm={6}>
            <h1 className="display-4 text-white">Alle Lichter aus?</h1>
            <p>
              Der angeforderte Inhalt wurde anscheinend ausgeknipst. Keine Angst. Bei uns bleiben Sie nicht im Dunkeln.{" "}
              {text}
            </p>
            <Button variant="secondary" as={Link} to={actionLinkTo}>
              {" "}
              <BsArrowRight className="text-primary" /> {actionCaption}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoMatch;
