import { useContext } from "react";
import { AxiosError, AxiosInstance } from "axios";

import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import { useErrorLogger } from "../common/LoggerHooks";
import { ApiContext } from "../services/ApiContext";

export interface QueryFuncData<TKey = string, TParam = undefined> {
  queryKey: [TKey, AxiosInstance, TParam?];
}

export function useGetData<TResult, TQueryKey extends QueryKey = QueryKey, TParam = undefined>(
  DataFunction: QueryFunction<TResult, [TQueryKey, AxiosInstance, TParam?]>,
  technicalContext: string,
  customeErrorMsg: string,
  qkey: TQueryKey,
  param?: TParam
): UseQueryResult<TResult, AxiosError> {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const queryObj: UseQueryOptions<TResult, AxiosError, TResult, [TQueryKey, AxiosInstance, TParam?]> = {
    queryKey: [qkey, api, param],
    queryFn: DataFunction,
    onError: (err) => logAxiosError("Error loading " + technicalContext, customeErrorMsg, err),
  };
  return useQuery(queryObj);
}
