import React, { useEffect } from "react";

import { Col, Row, Jumbotron, Container } from "react-bootstrap";
import RouteBreadcrumb from "../components/RouteBreadcrumb";
import { IoWarningOutline } from "react-icons/io5";

export const HomeNoFirma: React.FC = () => {
  // hooks
  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    document.title = "wMSB Portal";
  }, []);

  // daten
  // handler

  return (
    <>
      <Container>
        <RouteBreadcrumb matchUrl="/" />
      </Container>

      <Container>
        <Row className="mt-0">
          <Col md={12}>
            <Jumbotron>
              <h1 className="display-4">
                <strong>wMSB</strong>Portal
              </h1>
              <Row>
                <Col md={1}>
                  <IoWarningOutline size="3em" color="#00c713" />
                </Col>
                <Col md={11}>
                  <p>
                    Aktuell sind Sie für keinen unserer Kunden legitimiert.
                    <br /> Bitte kontaktieren Sie Ihren Ansprechpartner.
                  </p>
                </Col>
              </Row>
              <hr />
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </>
  );
};
