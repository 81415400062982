import React from "react";
import { Row } from "react-bootstrap";

import Tile from "./Tile";
import AnmeldungIcon from "./Tile/anmeldung.svg";
import UnterbrechungIcon from "./Tile/Baumassnahme.svg";
import AbmeldungIcon from "./Tile/Abmeldung.svg";
import WechselIcon from "./Tile/Geraetewechsel.svg";

const Prozesseinstieg: React.FC = () => {
  return (
    <Row className="mt-0">
      <Tile
        helpText="Beantragen Sie neue Messstellen oder beauftragen Sie die Gelsenwasser, vorhandene Messstellen zu übernehmen"
        title="Mess&shy;stellen&shy;betrieb ANMELDEN"
        toPath="/anmeldung"
        iconPath={AnmeldungIcon}
      />
      <Tile
        helpText="Melden Sie eine Baumaßnahme o.ä., wegen der Messstellen temporär außer Betrieb sein werden"
        title="Strom&shy;unter&shy;brechung MELDEN"
        toPath="/stromunterbrechung"
        iconPath={UnterbrechungIcon}
      />
      <Tile
        helpText="Nehmen Sie Messstellen dauerhaft außer Betrieb oder melden Sie den Messstellenbetrieb bei der Gelsenwasser ab"
        title="Mess&shy;stellen&shy;betrieb ABMELDEN"
        toPath="/abmeldung"
        iconPath={AbmeldungIcon}
      />
      <Tile
        helpText="Fordern Sie neue Geräte an, um die Messstelle umzubauen"
        title="Geräte&shy;wechsel ANFORDERN"
        toPath="/geraetewechsel"
        iconPath={WechselIcon}
      />
    </Row>
  );
};

export default Prozesseinstieg;
