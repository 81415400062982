import React, { ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { Footer } from "@gelsenwasser/react";
import Header from "./Header";

interface Props {
  children: ReactNode;
}

const OurFallbackComponent: React.VFC<FallbackProps> = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

export const Layout: React.FC<Props> = ({ children }: Props) => {
  // nur in PROD wird die Build-Nummer nicht mit angezeigt, in allen anderen Umgebungen schon
  const version =
    process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.toLowerCase() === "prod"
      ? process.env.REACT_APP_VERSION
      : `${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_BUILD})`;

  return (
    <>
      <Header />

      <ErrorBoundary FallbackComponent={OurFallbackComponent}>
        <main className="d-flex flex-column flex-fill outermain bg-gw-light">{children}</main>
      </ErrorBoundary>

      <Footer year={process.env.REACT_APP_YEAR} version={version} />
    </>
  );
};
