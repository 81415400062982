import { useContext } from "react";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { useErrorLogger } from "common/LoggerHooks";
import { ApiContext } from "services/ApiContext";
import { log } from "common/Logger";
import { Kunde } from "./UserTypes";
import { AuthContext } from "services/authentication";

export const useGetKunden = (): UseQueryResult<Array<Kunde>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();

  const GetKunden = async (): Promise<Array<Kunde>> => {
    const response = await api.get("/kunden");
    log.trace({ obj: response.data }, "result from get kunden");
    return response.data.value;
  };

  return useQuery<Array<Kunde>, AxiosError>({
    queryKey: "kunden",
    queryFn: GetKunden,
    enabled: isAuthenticated,
    onError: (err) => logAxiosError("Error loading Kunden", "Fehler beim Laden der Kunden", err),
    staleTime: 15 * 60 * 1000 /* 15 Minuten, Default: 0 Minuten */,
  });
};
