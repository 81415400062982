import { IRules } from "./IRules";

/**
 * Can Probs
 *
 * @export
 * @interface ICanProps
 */
export interface ICanProps {
  /**
   * The rules to be used for evaluation
   *
   * @type {IRules}
   * @memberof ICanProps
   */
  rules: IRules;
  /**
   * Role used to check if the action to perform is allowed
   *
   * @type {string}
   * @memberof ICanProps
   */
  role: string;
  /**
   * Action to perform
   *
   * @type {string}
   * @memberof ICanProps
   */
  perform: string;
  /**
   * optional data, used for dynamic role checks
   *
   * @type {*}
   * @memberof ICanProps
   */
  data?: unknown;
  /**
   * if the action can be performed, this function will be called
   *
   * @memberof ICanProps
   */
  yes: () => JSX.Element;
  /**
   * if the action can **not** be performed, this function will be called
   *
   * @memberof ICanProps
   */
  no: () => JSX.Element;
}

const check = (
  rules: IRules,
  role: string,
  action: string,
  data: unknown
): boolean => {
  const permissions = rules[role];

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const CanPerform = (
  rules: IRules,
  role: string,
  perform: string,
  data?: unknown
): boolean => check(rules, role, perform, data);

/**
 * Can perform an action with the given role
 *
 * @param {ICanProps} props
 * @returns {JSX.Element}
 */
export const Can = (props: ICanProps): JSX.Element =>
  check(props.rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};
