import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { log } from "../../common/Logger";
import User, { MyCompany } from "./UserTypes";
import { useErrorLogger } from "common/LoggerHooks";
import { useContext } from "react";
import { ApiContext } from "services/ApiContext";
import { AuthContext } from "services/authentication";

export const useGetUsers = (): UseQueryResult<Array<User>, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetUsers = async (): Promise<Array<User>> => {
    const response = await api.get("/benutzer");
    log.trace({ obj: response.data }, "result from get benutzer");
    return response.data.value.map(mapDataToUser);
  };

  const mapDataToUser = (item: Record<string, unknown>): User =>
    <User>{
      contactid: item["contactid"],
      email: item["emailaddress1"],
      phone: item["telephone1"],
      firstname: item["firstname"],
      lastname: item["lastname"],
      active: item["statecode"] === 0,
    };

  return useQuery<Array<User>, AxiosError>({
    queryKey: BenutzerQueryKeys.BenutzerListe,
    queryFn: GetUsers,
    onError: (err) => logAxiosError("Error loading Benutzer", "Fehler beim Laden der Benutzer", err),
  });
};

export const useGetMyCompany = (): UseQueryResult<MyCompany, AxiosError> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated();

  const getMyCompany = async (): Promise<MyCompany> => {
    const response = await api.get("/me");
    log.trace({ obj: response.data }, "result from get 'me'");
    return response.data.value.map(mapDataToMyCompany)[0];
  };

  const mapDataToMyCompany = (item: Record<string, unknown>): MyCompany =>
    <MyCompany>{
      name: item["name"],
      accountid: item["accountid"],
      isKunde: item["isKunde"],
      strasse: item["strasse"],
      ort: item["ort"],
      plz: item["plz"],
    };

  return useQuery<MyCompany, AxiosError>({
    queryKey: "mycompany",
    queryFn: getMyCompany,
    enabled: isAuthenticated,
    onError: (err) => logAxiosError("Error loading MyCompany", "Fehler beim Laden meines Unternehmens", err),
    staleTime: 15 * 60 * 1000 /* 15 Minuten, Default: 0 Minuten */,
  });
};

export const BenutzerQueryKeys = {
  BenutzerListe: "benutzer",
} as const;
