import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";

import styles from "./Tile.module.scss";
export interface TileProps {
  title: string;
  toPath: string;
  iconPath: string;
  onclick?: React.MouseEventHandler<HTMLElement> | undefined;
  helpText: string | JSX.Element;
}

const cx = classNames.bind(styles);
const buttonClassName = cx(
  {
    tile_action: true,
  },
  "t-1"
);

const Tile: React.VFC<TileProps> = ({ title, toPath, iconPath, onclick, helpText }: TileProps) => {
  const bodyClassName = cx("d-flex", "flex-column", "justify-content-between", {
    cardbody__help: !!helpText,
  });
  return (
    <Col md={6} lg={4} xl={3} className="mt-3">
      <Card className="text-center border-light h-100">
        <Card.Body className={bodyClassName}>
          <Card.Title style={{ fontSize: "1.25rem" }}>{title}</Card.Title>
          <Card.Text>
            <img src={iconPath} className="mt-4 mb-4" width={60} height={60} />
            {helpText && <figcaption className={styles.help}>{helpText}</figcaption>}
            <Button variant="outline-primary" className={buttonClassName} as={Link} to={toPath} onClick={onclick}>
              Starten
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Tile;
