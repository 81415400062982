import { AxiosError } from "axios";

import { UseQueryResult } from "react-query";

import { log } from "../../common/Logger";
import { QueryFuncData, useGetData } from "../../common/ApiBase";
import { AuftragsAnzahlData } from "./DashboardTypes";

export const useGetAnzahlMessstellen = (): UseQueryResult<number, AxiosError> => {
  async function getAnzahlMessstellen({ queryKey }: QueryFuncData): Promise<number> {
    const [, api] = queryKey;
    const response = await api.get("/messstelle/anzahl");
    log.trace({ obj: response.data }, "got result from 'anzahlMessstellen'");
    return response.data.value[0].count;
  }

  return useGetData<number, string>(
    getAnzahlMessstellen,
    "Error loading AnzahlMessstellen",
    "Fehler beim Laden der Anzahl Messstellen",
    "anzahlMessstellen"
  );
};

export const useGetAnzahlAuftraege = (): UseQueryResult<AuftragsAnzahlData[], AxiosError> => {
  async function getAnzahlAuftraege({ queryKey }: QueryFuncData): Promise<AuftragsAnzahlData[]> {
    const [, api] = queryKey;
    const response = await api.get("/auftraege/AnzahlLaufend");
    log.trace({ obj: response.data }, "got result from 'anzahlAuftraege'");
    return response.data.value.map(
      (item: Record<string, unknown>): AuftragsAnzahlData =>
        <AuftragsAnzahlData>{
          anzahl: item["count"],
          status: item["statuscode@OData.Community.Display.V1.FormattedValue"],
        }
    );
  }

  return useGetData<AuftragsAnzahlData[], string>(
    getAnzahlAuftraege,
    "Error loading AnzahlAuftraege",
    "Fehler beim Laden der Anzahl Aufträge",
    "anzahlAuftraege"
  );
};

export const useGetAnzahlAufgaben = (): UseQueryResult<number, AxiosError> => {
  async function getAnzahlAufgaben({ queryKey }: QueryFuncData): Promise<number> {
    const [, api] = queryKey;
    const response = await api.get("/aufgaben/anzahl");
    log.trace({ obj: response.data }, "got result from 'anzahlAufgaben'");
    return response.data.value[0].count;
  }

  return useGetData<number, string>(
    getAnzahlAufgaben,
    "Error loading AnzahlAufgaben",
    "Fehler beim Laden der Anzahl Aufgaben",
    "anzahlAufgaben"
  );
};

export const useGetAnzahlStandorte = (): UseQueryResult<number, AxiosError> => {
  async function getAnzahlStandorte({ queryKey }: QueryFuncData): Promise<number> {
    const [, api] = queryKey;
    const response = await api.get("/standorte/anzahl");
    log.trace({ obj: response.data }, "got result from 'anzahlStandorte'");
    if (response.data.value.length === 0) return 0;
    return response.data.value[0].count;
  }

  return useGetData<number, string>(
    getAnzahlStandorte,
    "Error loading AnzahlStandorte",
    "Fehler beim Laden der Anzahl Standorte",
    "anzahlStandorte"
  );
};

export const useGetAnzahlTickets = (): UseQueryResult<number, AxiosError> => {
  async function getAnzahlTickets({ queryKey }: QueryFuncData): Promise<number> {
    const [, api] = queryKey;
    const response = await api.get("/tickets/anzahl");
    log.trace({ obj: response.data }, "got result from 'anzahlTickets'");
    return response.data.value[0].count;
  }

  return useGetData<number, string>(
    getAnzahlTickets,
    "Error loading AnzahlTickets",
    "Fehler beim Laden der Anzahl Tickets",
    "anzahlTickets"
  );
};
